import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { FocusTrapModule } from 'primeng/focustrap';
import { RatingModule } from 'primeng/rating';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';

import { HomeWelcomeComponent } from './components/home-welcome/home-welcome.component';
import { SampleComponent } from './components/sample/sample.component';
import { MdBarComponent } from './components/ui-components/md-bar/md-bar.component';
import { MdButtonComponent } from './components/ui-components/md-button/md-button.component';
import { MdCalendarComponent } from './components/ui-components/md-calendar/md-calendar.component';
import { MdCheckboxComponent } from './components/ui-components/md-checkbox/md-checkbox.component';
import { MdCodeEditorComponent } from './components/ui-components/md-code-editor/md-code-editor.component';
import { MdDropdownComponent } from './components/ui-components/md-dropdown/md-dropdown.component';
import { MdFileDropComponent } from './components/ui-components/md-file-drop/md-file-drop.component';
import { MdFileUploadComponent } from './components/ui-components/md-file-upload/md-file-upload.component';
import { MdJsonEditorComponent } from './components/ui-components/md-json-editor/md-json-editor.component';
import { MdLabelComponent } from './components/ui-components/md-label/md-label.component';
import { MdModalComponent } from './components/ui-components/md-modal/md-modal.component';
import { MdPlainTableComponent } from './components/ui-components/md-plain-table/md-plain-table.component';
import { MdRadioButtonComponent } from './components/ui-components/md-radio-button/md-radio-button.component';
import { MdRatingComponent } from './components/ui-components/md-rating/md-rating.component';
import { MdSideMenuComponent } from './components/ui-components/md-side-menu/md-side-menu.component';
import { MdSortIconComponent } from './components/ui-components/md-sort-icon/md-sort-icon.component';
import { MdSpinnerComponent } from './components/ui-components/md-spinner/md-spinner.component';
import { MdSwitchComponent } from './components/ui-components/md-switch/md-switch.component';
import { MdTableComponent } from './components/ui-components/md-table/md-table.component';
import { MdTableGenericComponent } from './components/ui-components/md-table-generic/md-table-generic.component';
import { MdTableLazyComponent } from './components/ui-components/md-table-lazy/md-table-lazy.component';
import { MdTextareaComponent } from './components/ui-components/md-textarea/md-textarea.component';
import { MdTextboxComponent } from './components/ui-components/md-textbox/md-textbox.component';
import { MdTitleComponent } from './components/ui-components/md-title/md-title.component';
import { MdToasterComponent } from './components/ui-components/md-toaster/md-toaster.component';
import { MdTooltipComponent } from './components/ui-components/md-tooltip/md-tooltip.component';
import { MdTutorialBoxComponent } from './components/ui-components/md-tutorial-box/md-tutorial-box.component';
import { MdAutoFocusDirective } from './directives/md-auto-focus.directive';
import { MdResizeObserverDirective } from './directives/md-resize-observer.directive';
import { EmptyDashPipe } from './pipes/empty-dash.pipe';

@NgModule({
  declarations: [
    HomeWelcomeComponent,
    SampleComponent,
    MdBarComponent,
    MdButtonComponent,
    MdCalendarComponent,
    MdCheckboxComponent,
    MdCodeEditorComponent,
    MdDropdownComponent,
    MdFileDropComponent,
    MdFileUploadComponent,
    MdJsonEditorComponent,
    MdLabelComponent,
    MdModalComponent,
    MdPlainTableComponent,
    MdRadioButtonComponent,
    MdRatingComponent,
    MdSideMenuComponent,
    MdSortIconComponent,
    MdSpinnerComponent,
    MdSwitchComponent,
    MdTableComponent,
    MdTableGenericComponent,
    MdTableLazyComponent,
    MdTextareaComponent,
    MdTextboxComponent,
    MdTitleComponent,
    MdToasterComponent,
    MdTooltipComponent,
    MdTutorialBoxComponent,
    MdAutoFocusDirective,
    MdResizeObserverDirective,
    EmptyDashPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CalendarModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    TableModule,
    ToastModule,
    TooltipModule,
    FocusTrapModule,
    RatingModule,
    OverlayPanelModule,
    InputSwitchModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CalendarModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    TableModule,
    ToastModule,
    TooltipModule,
    FocusTrapModule,
    RatingModule,
    OverlayPanelModule,
    InputSwitchModule,
    HomeWelcomeComponent,
    SampleComponent,
    MdBarComponent,
    MdButtonComponent,
    MdCalendarComponent,
    MdCheckboxComponent,
    MdCodeEditorComponent,
    MdDropdownComponent,
    MdFileDropComponent,
    MdFileUploadComponent,
    MdJsonEditorComponent,
    MdLabelComponent,
    MdModalComponent,
    MdPlainTableComponent,
    MdRadioButtonComponent,
    MdRatingComponent,
    MdSideMenuComponent,
    MdSortIconComponent,
    MdSpinnerComponent,
    MdSwitchComponent,
    MdTableComponent,
    MdTableGenericComponent,
    MdTableLazyComponent,
    MdTextareaComponent,
    MdTextboxComponent,
    MdTitleComponent,
    MdToasterComponent,
    MdTooltipComponent,
    MdTutorialBoxComponent,
    MdAutoFocusDirective,
    MdResizeObserverDirective,
    EmptyDashPipe,
  ],
})
export class SharedModule {}
