import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, map, take } from 'rxjs';
import { user } from '../models/common.model';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private user$ = new BehaviorSubject<user>(null);
  get user(): Observable<user> {
    return this.user$.asObservable();
  }
  setUser(user: user) {
    this.user$.next(user);
  }
  get isAuthenticated() {
    return this.user.pipe(map((user) => (user ? true : false)));
  }
  get isAdmin() {
    return this.user.pipe(map((user) => (user && user.isadmin == 1 ? true : false)));
  }
  private userAvatar$ = new BehaviorSubject<SafeUrl>(null);
  get userAvatar(): Observable<SafeUrl> {
    return this.userAvatar$.asObservable();
  }
  setUserAvatar(avatar: SafeUrl) {
    this.userAvatar$.next(avatar);
  }
  private toaster$ = new Subject<any>();
  get toaster(): Observable<any> {
    return this.toaster$.asObservable();
  }
  setToaster(data: any) {
    this.toaster$.next(data);
  }
  private lightModeOn$ = new BehaviorSubject<boolean>(false);
  get lightModeOn(): Observable<boolean> {
    return this.lightModeOn$.asObservable();
  }
  setLightModeOn(lightModeOn: boolean) {
    this.lightModeOn$.next(lightModeOn);
    localStorage.setItem('lightModeOn', lightModeOn.toString());
  }
  private appColorMode$ = new BehaviorSubject<number>(0);
  get appColorMode(): Observable<number> {
    return this.appColorMode$.asObservable();
  }
  setAppColorMode(colorMode: number) {
    this.appColorMode$.next(colorMode);
  }
  private appOverFlowHidden$ = new BehaviorSubject<boolean>(false);
  get appOverFlowHidden(): Observable<boolean> {
    return this.appOverFlowHidden$.asObservable();
  }
  setAppOverFlowHidden(hidden: boolean) {
    this.appOverFlowHidden$.next(hidden);
  }
  sendAnalyticsEvent(eventName: string, eventParameters: any = {}) {
    this.user.pipe(take(1)).subscribe((user) => {
      if (
        user != null &&
        (user.email.includes('@makedata.ai') ||
          user.email.includes('@objectsystems.com') ||
          user.email.includes('@cleancodefactory.de'))
      ) {
        return;
      }
      gtag('event', eventName, eventParameters);
    });
  }
  downloadFile(data: any, format: 'JSON' | 'CSV' | 'Sqlite' | 'Csharp', name: string) {
    if (format == 'JSON') {
      const jsonStr = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonStr], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name + '.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else if (format == 'CSV') {
      const array = data || [];
      const headers = Object.keys(array[0]);
      const csvRows = [];
      csvRows.push(headers.join(','));
      for (const row of array) {
        const values = headers.map((header) => {
          let val = row[header] !== null && row[header] !== undefined ? row[header] : '';
          val = val.toString().replace(/"/g, '""'); // Escape double quotes
          if (val.search(/("|,|\n)/g) >= 0) {
            val = `"${val}"`;
          }
          return val;
        });
        csvRows.push(values.join(','));
      }
      const csvData = csvRows.join('\r\n');
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name + '.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else if (format == 'Sqlite') {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else if (format == 'Csharp') {
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name + '.cs';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  }
}
